<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Fulltime -- Zeek Schedule"/>

        <v-spacer></v-spacer>

        

      </v-toolbar> 



      <v-divider></v-divider>



        <template>
            <v-dialog v-model="showShiftDialog" v-if="selSchedule">
                <v-card>
                    <v-card-title>
                        {{ selSchedule.zeek_schedule_id }} ({{ selSchedule.service_area_code }})
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="shiftHeaders"
                            :items="zeek_schedule_shifts"
                            :items-per-page="100"
                            :footer-props="footerProps"
                            class="elevation-1"
                            dense
                        >
                            <template v-slot:item.dm_employee="{ item }">
                                <div v-if="!item.employee">
                                    [Not found]
                                </div>
                                <div v-else>
                                    {{ item.employee.name_th }}
                                </div>
                            </template>

                            <template v-slot:item.apply_date="{ item }">
                                {{ moment(item.apply_date).format("YYYY-MM-DD") }}
                            </template>

                            <template v-slot:item.plan_schedule="{ item }">
                                Start {{ moment(item.starttime).format("HH:mm") }} <br>
                                To {{ moment(item.endtime).format("HH:mm") }} <br>
                                {{ item.schedule_hour }} Hour
                            </template>

                            <template v-slot:item.actual_schedule="{ item }">
                                Start {{ moment(item.actual_starttime).format("HH:mm") }} <br>
                                To {{ moment(item.actual_endtime).format("HH:mm") }} <br>
                                {{ item.online_hour }} Hour
                            </template>

                            <template v-slot:item.zeek_updated_at="{ item }">
                                {{ moment(item.zeek_updated_at).format("YYYY-MM-DD HH:mm:ss") }}
                            </template>

                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => { showShiftDialog = false; }">Cancel</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </template>

        <template>
            <v-dialog v-model="showPaymentDialog" v-if="selSchedule">
                <v-card>
                    <v-card-title>
                        {{ selSchedule.zeek_schedule_id }} ({{ selSchedule.service_area_code }})
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="paymentHeaders"
                            :items="selSchedule.zeek_roster_payment"
                            :items-per-page="100"
                            :footer-props="footerProps"
                            class="elevation-1"
                            dense
                        >

                            <template v-slot:item.payment_schedule="{ item }">
                                {{ item.fulltime_payment_schedule.period_start_date }} - {{ item.fulltime_payment_schedule.period_end_date }}
                            </template>

                            <template v-slot:item.is_processed="{ item }">
                                {{ item.fulltime_payment_schedule.is_processed ? "Paid" : "Waiting" }}
                            </template>

                            

                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => { showPaymentDialog = false; }">Cancel</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </template>
        <div class="content-wrap">

            <v-row justify="end" >
                <v-col cols="5" sm="4" md="3" >
                    <v-menu
                    v-model="open.search_date_from"
                    :close-on-content-click="false"
                    max-width="290"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="search.search_date_from"
                                label="From"
                                prepend-icon="mdi-calendar"
                                readonly
                                hide-details
                                clearable
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="search.search_date_from"
                            no-title
                            scrollable
                            @change="open.search_date_from = false"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="5" sm="4" md="3" >
                    <v-menu
                    v-model="open.search_date_to"
                    :close-on-content-click="false"
                    max-width="290"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="search.search_date_to"
                                label="To"
                                prepend-icon="mdi-calendar"
                                readonly
                                hide-details
                                clearable
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="search.search_date_to"
                            no-title
                            scrollable
                            @change="open.search_date_to = false"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" sm="2" md="2" >
                    <v-btn color="primary" @click="() => {reloadData();}" small class="mt-3">
                        Search
                    </v-btn>
                </v-col>
            </v-row>

           <v-row>
                
              <v-col cols="12" sm="12" md="12"  >
                    <v-data-table
                        :headers="headers"
                        :items="zeek_schedules"
                        item-key="zeek_roster_id"
                        :footer-props="footerProps"
                        class="elevation-1"
                        :options.sync="options"
                        :server-items-length="totalResult"
                        dense
                        
                        >

                        <template v-slot:item.schedule_period="{ item }">
                        
                            {{ item.start_date }} - {{ item.end_date }}
                        </template>

                        <template v-slot:item.payments="{ item }">
                            {{ item.zeek_roster_payment.length }} / {{ moment(item.end_date).diff(moment(item.start_date), 'days') + 1 }}
                            <br>
                            <v-btn x-small @click="() => { loadZeekPayment(item) }">View</v-btn>
                        </template>

                        <template v-slot:item.shifts="{ item }">
                            <v-btn x-small @click="() => { loadZeekShift(item) }">View</v-btn>
                        </template>

                        <template v-slot:item.zeek_updated_at="{ item }">
                            {{ moment(item.zeek_updated_at).format("YYYY-MM-DD HH:mm:ss") }}
                        </template>
                    </v-data-table>

              </v-col>
          </v-row>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
const moment = require('moment-timezone');
const lodash = require('lodash');

export default {
    name: 'FulltimePaymentSchedule',
    data: () => ( {
        selSchedule : null,
        selPayment : [],
        showShiftDialog : false,
        showPaymentDialog : false,
        zeek_schedules : [],
        zeek_schedule_shifts : [],

        totalResult : 0,
        search : {search_date_from : moment().subtract(1, "months").format("YYYY-MM-DD"), search_date_to : ""},
        open : {search_date_from : false, search_date_to : false},
        footerProps: {'items-per-page-options': [50, 100, 500]},
        today : null,
        headers: [
            { text: 'Zeek Schedule Id', value: 'zeek_schedule_id', width: 150, sortable : false },
            { text: 'Service Area Code', value: 'service_area_code', width: 150, sortable : false },
            { text: 'Period', value: 'schedule_period', sortable : false },
            { text: 'Payment', value: 'payments', sortable : false },
            { text: 'Shift', value: 'shifts', sortable : false},
            { text: 'Last Update', value: 'zeek_updated_at', width: 120, sortable : false },

        ],
        options: { 
            page: 1,
            itemsPerPage: 100,
        },
        shiftHeaders : [
            { text: 'Tasker ID', value: 'tasker_id', sortable : false},
            { text: 'Client Partner ID', value: 'client_partner_id', sortable : false },
            { text: 'DM Employee Name', value: 'dm_employee', sortable : false },
            { text: 'Area Code', value: 'area_code', sortable : false },
            { text: 'Date', value: 'apply_date', sortable : false},
            { text: 'Plan', value: 'plan_schedule', sortable : false },
            { text: 'Actual', value: 'actual_schedule', sortable : false },
            { text: 'Amount', value: 'paid_amount', sortable : false },
            { text: 'Last Update', value: 'zeek_updated_at', sortable : false },
        ],
        paymentHeaders : [
            { text: 'Date', value: 'zeek_roster_schedule_date', sortable : false},
            { text: 'Payment Name', value: 'fulltime_payment_schedule.name', sortable : false },
            { text: 'Schedule', value: 'payment_schedule', sortable : false },
            { text: 'Paid Date', value: 'fulltime_payment_schedule.paid_date', sortable : false },
            { text: 'Paid Status', value: 'is_processed', sortable : false},
        ],
        validate_rules : {
            required: v => !!v || 'This field is required',
            filesize:  v => !v || v.size < 2048*1024 || 'Updload size should be less than 2 MB!',
            filetype:  v => {
                if(v){
                var ext = v.name.split('.').pop();
                    return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
                } else {
                    return 'This field is required'
                }
            }
        },
    }),
    components: {
        pageHeading,

    },
    methods: {
         ...mapActions(['genCan', 'getZeekSchedule', 'getZeekShift' ]),
        isToday(inp_date) {
            const moment = require('moment-timezone');

            let ret_class = [];
            if (inp_date == moment().tz('Asia/Bangkok').format("YYYY-MM-DD"))
            {
                ret_class.push('amber');
                ret_class.push('lighten-4');
            }
            return ret_class;
        },

        async reloadData() {

            let op = {
                limit : this.options.itemsPerPage,
                offset : (this.options.page - 1) * this.options.itemsPerPage,
                ...(this.search.search_date_from.trim() == "" ? {} : { search_date_from : this.search.search_date_from.trim() } ),
                ...(this.search.search_date_to.trim() == "" ? {} : { search_date_to : this.search.search_date_to.trim() } )
            }

            let schedule_response = await this.getZeekSchedule(op);

            this.zeek_schedules = schedule_response.data;
            this.totalResult = schedule_response.count;

        },

        async loadZeekShift(schedule) {
            let shifts = await this.getZeekShift({zeek_schedule_id : schedule.zeek_schedule_id});
            console.log(shifts);
            this.zeek_schedule_shifts = shifts;
            this.showShiftDialog = true;
            this.selSchedule = schedule;
        },

        async loadZeekPayment(schedule) {
            this.showPaymentDialog = true;
            this.selSchedule = schedule;
        }
    },
    async beforeMount() {
        this.genCan();
        this.reloadData();
    },

    computed: {
        ...mapGetters(['canDo','allCategories'])
    },
    mounted() {
    }
};
</script>