var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('pageHeading',{attrs:{"pageTitle":"Fulltime -- Zeek Schedule"}}),_c('v-spacer')],1),_c('v-divider'),[(_vm.selSchedule)?_c('v-dialog',{model:{value:(_vm.showShiftDialog),callback:function ($$v) {_vm.showShiftDialog=$$v},expression:"showShiftDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selSchedule.zeek_schedule_id)+" ("+_vm._s(_vm.selSchedule.service_area_code)+") ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.shiftHeaders,"items":_vm.zeek_schedule_shifts,"items-per-page":100,"footer-props":_vm.footerProps,"dense":""},scopedSlots:_vm._u([{key:"item.dm_employee",fn:function(ref){
var item = ref.item;
return [(!item.employee)?_c('div',[_vm._v(" [Not found] ")]):_c('div',[_vm._v(" "+_vm._s(item.employee.name_th)+" ")])]}},{key:"item.apply_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.apply_date).format("YYYY-MM-DD"))+" ")]}},{key:"item.plan_schedule",fn:function(ref){
var item = ref.item;
return [_vm._v(" Start "+_vm._s(_vm.moment(item.starttime).format("HH:mm"))+" "),_c('br'),_vm._v(" To "+_vm._s(_vm.moment(item.endtime).format("HH:mm"))+" "),_c('br'),_vm._v(" "+_vm._s(item.schedule_hour)+" Hour ")]}},{key:"item.actual_schedule",fn:function(ref){
var item = ref.item;
return [_vm._v(" Start "+_vm._s(_vm.moment(item.actual_starttime).format("HH:mm"))+" "),_c('br'),_vm._v(" To "+_vm._s(_vm.moment(item.actual_endtime).format("HH:mm"))+" "),_c('br'),_vm._v(" "+_vm._s(item.online_hour)+" Hour ")]}},{key:"item.zeek_updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.zeek_updated_at).format("YYYY-MM-DD HH:mm:ss"))+" ")]}}],null,false,3517830997)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function () { _vm.showShiftDialog = false; }}},[_vm._v("Cancel")])],1)],1)],1):_vm._e()],[(_vm.selSchedule)?_c('v-dialog',{model:{value:(_vm.showPaymentDialog),callback:function ($$v) {_vm.showPaymentDialog=$$v},expression:"showPaymentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selSchedule.zeek_schedule_id)+" ("+_vm._s(_vm.selSchedule.service_area_code)+") ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.paymentHeaders,"items":_vm.selSchedule.zeek_roster_payment,"items-per-page":100,"footer-props":_vm.footerProps,"dense":""},scopedSlots:_vm._u([{key:"item.payment_schedule",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fulltime_payment_schedule.period_start_date)+" - "+_vm._s(item.fulltime_payment_schedule.period_end_date)+" ")]}},{key:"item.is_processed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fulltime_payment_schedule.is_processed ? "Paid" : "Waiting")+" ")]}}],null,false,4236244632)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function () { _vm.showPaymentDialog = false; }}},[_vm._v("Cancel")])],1)],1)],1):_vm._e()],_c('div',{staticClass:"content-wrap"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From","prepend-icon":"mdi-calendar","readonly":"","hide-details":"","clearable":""},model:{value:(_vm.search.search_date_from),callback:function ($$v) {_vm.$set(_vm.search, "search_date_from", $$v)},expression:"search.search_date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.open.search_date_from),callback:function ($$v) {_vm.$set(_vm.open, "search_date_from", $$v)},expression:"open.search_date_from"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.open.search_date_from = false}},model:{value:(_vm.search.search_date_from),callback:function ($$v) {_vm.$set(_vm.search, "search_date_from", $$v)},expression:"search.search_date_from"}})],1)],1),_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To","prepend-icon":"mdi-calendar","readonly":"","hide-details":"","clearable":""},model:{value:(_vm.search.search_date_to),callback:function ($$v) {_vm.$set(_vm.search, "search_date_to", $$v)},expression:"search.search_date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.open.search_date_to),callback:function ($$v) {_vm.$set(_vm.open, "search_date_to", $$v)},expression:"open.search_date_to"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.open.search_date_to = false}},model:{value:(_vm.search.search_date_to),callback:function ($$v) {_vm.$set(_vm.search, "search_date_to", $$v)},expression:"search.search_date_to"}})],1)],1),_c('v-col',{attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","small":""},on:{"click":function () {_vm.reloadData();}}},[_vm._v(" Search ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.zeek_schedules,"item-key":"zeek_roster_id","footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.totalResult,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.schedule_period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date)+" - "+_vm._s(item.end_date)+" ")]}},{key:"item.payments",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.zeek_roster_payment.length)+" / "+_vm._s(_vm.moment(item.end_date).diff(_vm.moment(item.start_date), 'days') + 1)+" "),_c('br'),_c('v-btn',{attrs:{"x-small":""},on:{"click":function () { _vm.loadZeekPayment(item) }}},[_vm._v("View")])]}},{key:"item.shifts",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function () { _vm.loadZeekShift(item) }}},[_vm._v("View")])]}},{key:"item.zeek_updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.zeek_updated_at).format("YYYY-MM-DD HH:mm:ss"))+" ")]}}])})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }